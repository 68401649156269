import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  getPackage,
  packageSelector,
  searchCategories,
  setReduxStorePackageDetails,
  updatePackage,
  updatePackageImage,
  updatePackageItem,
  updatePackageStatus,
} from "../../../services/package/package-slice.service";
import FormFieldLabel from "../../business/components/form-field-label.component";
import CategoryMultipleSelect from "../components/category-multiple-select.component";
import CategorySingleSelect from "../components/category-single-select.component";
import ImageUpload from "../components/image-upload.component";
import PackageDetailAdd from "../components/package-detail-add.component";
import PackageDetail from "../components/package-detail.component";
import SpecificListAutoComplete from "../components/specific-list-autocomplete.component";
import StatusSingleSelect from "../components/status-single-select.component";
import SubscriptionPlansListAutoComplete from "../components/subscription-plans-list-autocomplete.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const InactiveActiveStatus = [
  { id: 1, label: "Active" },
  { id: 2, label: "Inactive" },
];

const PendingActiveStatus = [
  { id: 1, label: "Active" },
  { id: 2, label: "Pending" },
];

export default function PackageEditScreen() {
  const formatString = (str) =>
    str
      .split("_") // Split by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join with space

  const theme = useTheme();
  const location = useLocation();
  const selectedPlanId = location.state.id;
  const selectedCategoryModel = formatString(location.state.category.model);
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [filePreview, setFilePreview] = useState(location.state.imagePath);
  const [categoryType, setCategoryType] = useState(selectedCategoryModel);
  const { searchCategoriesObj, getPackageObj, reduxStorePackageDetails } =
    useSelector(packageSelector);
  const [allowEditImage, setAllowEditImage] = useState(false);
  const [allowEditStatus, setAllowEditStatus] = useState(false);
  const [allowEditPlan, setAllowEditPlan] = useState(false);
  const [allowEditItem, setAllowEditItem] = useState(false);
  const [allowEditDetail, setAllowEditDetail] = useState(false);

  const validationSchema = Yup.object().shape({
    businesses: Yup.array().required().min(1).label("Businesses"),
    name: Yup.string()
      .required()
      .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
      .label("Name"),
    priceRM: Yup.number().max(10000).required().label("Price"),
    membershipPriceRM: Yup.number()
      .lessThan(Yup.ref("priceRM"))
      .nullable()
      .label("Membership Price"),
    noOfSessions: Yup.number().required().label("No. of Sessions"),
    expireNoDaysFromPurchaseDate: Yup.number()
      .nullable()
      .label("Expire fitness session after purchase (Days)"),
    categoryModel: Yup.string().required().label("Category Type"),
    categoryId: Yup.array(Yup.number())
      .when("categoryModel", (categoryModel, schema) =>
        categoryModel === "Hourly Booking" ? schema.notRequired() : schema.min(1),
      )
      .label("Categories"),
  });

  const validationSchemaImage = Yup.object().shape({
    image: Yup.string().required().label("Image"),
  });

  const validationSchemaStatus = Yup.object().shape({
    statusAlias: Yup.string().required().label("Status"),
  });

  const validationSchemaItem = Yup.object().shape({
    subscriptions: Yup.array().label("Subscriptions"),
  });

  const renderCategories = () => {
    if (categoryType === "Fitness") return searchCategoriesObj.data.fitness;
    if (categoryType === "Wellness") return searchCategoriesObj.data.wellness;
    if (categoryType === "Health") return searchCategoriesObj.data.health;
    return [];
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(setReduxStorePackageDetails([]));
    dispatch(getPackage({ planId: selectedPlanId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setFilePreview(payload.data.imagePath);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        dispatch(setReduxStorePackageDetails(payload.data.details));
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });

    dispatch(searchCategories("")).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onUpdatePackage = (values) => {
    const priceCents = values.priceRM * 100;
    let membershipPriceCents = values.membershipPriceRM ? values.membershipPriceRM * 100 : null;
    if (values.requireMembership) {
      membershipPriceCents = null;
    }
    const businessesId = values.businesses.map((item) => item.id);
    setIsLoading(true);
    dispatch(
      updatePackage({
        planId: selectedPlanId,
        priceCents,
        membershipPriceCents,
        businessesId,
        ...values,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      setIsLoading(false);
    });
    setAllowEditPlan(false);
  };

  const onUpdatePackageImage = (values) => {
    dispatch(updatePackageImage({ planId: selectedPlanId, ...values })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
        setIsLoading(false);
      },
    );
    setAllowEditImage(false);
  };

  const onUpdatePackageStatus = (values) => {
    dispatch(updatePackageStatus({ planId: selectedPlanId, ...values })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
        setIsLoading(false);
      },
    );
    setAllowEditStatus(false);
  };

  const onUpdatePackageItem = (values) => {
    const subscriptionPlansId = values.subscriptions.map((item) => item.id);

    dispatch(updatePackageItem({ planId: selectedPlanId, subscriptionPlansId, ...values })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
        setIsLoading(false);
      },
    );
    setAllowEditItem(false);
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      {getPackageObj.status === "succeeded" && searchCategoriesObj.status === "succeeded" && (
        <>
          <Formik
            validationSchema={validationSchemaImage}
            onSubmit={onUpdatePackageImage}
            initialValues={{
              image: getPackageObj.data.imagePath,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                    paddingTop: theme.dimensions.ScreenPaddingY,
                  }}
                >
                  <Grid item xs={12}>
                    <SpaceBetweenBox>
                      <Text variant="screenLabel">Edit Package Plan</Text>
                      <Button
                        onClick={() => history.push(routes.PACKAGE_LIST)}
                        sx={{
                          textTransform: "none",
                          padding: "0px",
                          ":hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <Text>Back</Text>
                      </Button>
                    </SpaceBetweenBox>
                  </Grid>
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Image</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditImage ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditImage(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditImage(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Image</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Image</Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <ImageUpload
                                  name="image"
                                  disabled={!allowEditImage}
                                  filePreview={filePreview}
                                  setFilePreview={setFilePreview}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <Formik
            validationSchema={validationSchemaStatus}
            onSubmit={onUpdatePackageStatus}
            initialValues={{
              statusAlias:
                getPackageObj.data.status.charAt(0).toUpperCase() +
                getPackageObj.data.status.slice(1),
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Status</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditStatus ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditStatus(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditStatus(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Status</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Status
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <StatusSingleSelect
                                  name="statusAlias"
                                  placeholder="Choose status"
                                  label="Status"
                                  disabled={!allowEditStatus}
                                  itemList={
                                    getPackageObj?.data?.status === "pending"
                                      ? PendingActiveStatus
                                      : InactiveActiveStatus
                                  }
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          {categoryType === "Fitness" && (
            <Formik
              validationSchema={validationSchemaItem}
              onSubmit={onUpdatePackageItem}
              initialValues={{
                subscriptions: getPackageObj.data.items,
              }}
            >
              {({ handleSubmit }) => (
                <Form>
                  <Grid
                    container
                    spacing={5}
                    sx={{
                      paddingX: isMobile
                        ? theme.dimensions.MobilePadding
                        : theme.dimensions.PCPadding,
                    }}
                  >
                    <Grid item xs={12}>
                      <WhiteBgCardContainer>
                        <Grid container spacing={isMobile ? 1 : 3}>
                          <Grid item xs={4} sm={2}>
                            <Text variant="screenLabel">Item</Text>
                          </Grid>
                          <Grid item xs={4} sm={2}>
                            {allowEditItem ? (
                              <>
                                <IconButton size="small" onClick={handleSubmit}>
                                  <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton size="small" onClick={() => setAllowEditItem(false)}>
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setAllowEditItem(true);
                                  }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          </Grid>

                          <Grid item xs={12}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Subscription(s)
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Subscription(s)
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <SubscriptionPlansListAutoComplete
                                    name="subscriptions"
                                    placeholder="Subscription Plans"
                                    disabled={!allowEditItem}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                        </Grid>
                      </WhiteBgCardContainer>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}

          <Formik
            validationSchema={validationSchema}
            onSubmit={onUpdatePackage}
            initialValues={{
              businesses: getPackageObj.data.businesses,
              name: getPackageObj.data.name,
              priceRM: (getPackageObj.data.priceCents / 100).toFixed(2),
              membershipPriceRM:
                getPackageObj.data.membershipPrice !== null
                  ? Number(getPackageObj.data.membershipPrice.replace(/[^0-9.]+/g, "")).toFixed(2)
                  : null,
              noOfSessions: getPackageObj.data.noOfSessions,
              expireNoDaysFromPurchaseDate: getPackageObj.data.expireNoDaysFromPurchaseDate
                ? getPackageObj.data.expireNoDaysFromPurchaseDate
                : "",
              categoryModel:
                getPackageObj.data.category.model === "health"
                  ? "Health"
                  : formatString(getPackageObj.data.category.model),
              categoryId: getPackageObj.data.category.items.map((item) => item.id),
              category: {
                model:
                  getPackageObj.data.category.model === "health"
                    ? "health"
                    : getPackageObj.data.category.model,
                ids: getPackageObj.data.category.items.map((item) => item.id),
              },
              requireMembership: getPackageObj.data.businesses.some(
                (item) => item.requireMembership,
              ),
            }}
          >
            {({ handleSubmit, dirty, values }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Plan</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditPlan ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditPlan(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditPlan(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>
                        {!!values.requireMembership && (
                          <Grid item xs={12}>
                            <Text type="RedColor">*This business require membership</Text>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Businesses
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Businesses
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <SpecificListAutoComplete
                                  name="businesses"
                                  placeholder="Businesses"
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                        <Grid item xs={12}>
                          <FormFieldLabel
                            name="name"
                            placeholder="Enter package name"
                            label="Package Name"
                            disabled={!allowEditPlan}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormPrice
                                  placeholder="00.00"
                                  name="priceRM"
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                        {!values.requireMembership && (
                          <Grid item xs={12}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Membership Price
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Membership Price
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormPrice
                                    placeholder="00.00"
                                    name="membershipPriceRM"
                                    disabled={!allowEditPlan}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                No. of Sessions
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  No. of Sessions
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormFieldText
                                  name="noOfSessions"
                                  placeholder="0"
                                  label="No. of Sessions"
                                  type="number"
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                        <Grid item xs={12}>
                          {isMobile && (
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Expire fitness session after purchase (Days)
                            </Text>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Expire session after purchase (Days)
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormFieldText
                                  name="expireNoDaysFromPurchaseDate"
                                  placeholder="0"
                                  label="Expire session after purchase (Days)"
                                  type="number"
                                  disabled={!allowEditPlan}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>{" "}
                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Category Type
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Category Type
                                </Text>
                              </LabelContainer>
                            )}
                            {console.log(values.categoryModel)}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <CategorySingleSelect
                                  name="categoryModel"
                                  placeholder="Choose category type"
                                  label="Category Type"
                                  itemList={[
                                    { id: 1, label: "Fitness" },
                                    { id: 2, label: "Wellness" },
                                    { id: 3, label: "Health" },
                                    { id: 4, label: "Hourly Booking" },
                                  ]}
                                  setCategoryType={setCategoryType}
                                  disabled
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                        {categoryType !== "Hourly Booking" && (
                          <Grid item xs={12}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Category(s)
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Category(s)
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <CategoryMultipleSelect
                                    name="categoryId"
                                    placeholder="Choose categories"
                                    itemList={renderCategories()}
                                    disabled={!allowEditPlan}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                        )}
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <PackageDetailAdd
                          selectedPlanId={selectedPlanId}
                          allowEditDetail={allowEditDetail}
                          setAllowEditDetail={setAllowEditDetail}
                        />

                        {reduxStorePackageDetails.length > 0 && (
                          <>
                            {reduxStorePackageDetails.map((detail) => (
                              <PackageDetail
                                key={detail.id}
                                detail={detail}
                                planId={selectedPlanId}
                                allowEditDetail={allowEditDetail}
                              />
                            ))}
                          </>
                        )}
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Grid>
  );
}
