import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, Chip, Grid, ListItemText, OutlinedInput, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import Text from "../../../components/text.component";

export default function CategoryMultipleSelect({
  itemList,
  name,
  placeholder,
  width,
  isTransparent,
  disabled,
}) {
  const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext("");
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (event) => {
    const newPayload = { model: values.category.model, ids: event.target.value };

    setFieldValue("category", newPayload);
    setFieldValue(
      name,
      typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value,
    );
  };

  return (
    <FormControl sx={{ width }}>
      <Select
        multiple
        onBlur={() => setFieldTouched(name)}
        value={values[name]}
        disabled={disabled}
        onChange={handleChange}
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
        }}
        input={<OutlinedInput />}
        error={showError}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Text type="GreyColor">{placeholder}</Text>;
          }

          return (
            <Grid container sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((item) => (
                <Grid key={item}>
                  <Chip
                    label={itemList.find((x) => x.id === item)?.name}
                    sx={{ textTransform: "capitalize" }}
                  />
                </Grid>
              ))}
            </Grid>
          );
        }}
      >
        {itemList.length === 0 && (
          <MenuItem value="" disabled>
            <Text type="GreyColor">Select Category Type First</Text>
          </MenuItem>
        )}
        {itemList.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox checked={values[name].indexOf(item.id) > -1} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error={showError}>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

CategoryMultipleSelect.defaultProps = {
  width: "100%",
  isTransparent: false,
  disabled: false,
};

CategoryMultipleSelect.propTypes = {
  width: PropTypes.string,
  itemList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
  disabled: PropTypes.bool,
};
