import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import Text from "../../../components/text.component";
import { getUserPermission, getUserRole } from "../../../infrastructure/utils";

export default function UserTableRow({ user }) {
  const theme = useTheme();
  const history = useHistory();
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <TableRow
        key={user.user.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`${user.user.firstName} ${user.user.lastName}`}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {user.user.phoneNumber}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {user.user.email}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {user.user.purchaseSummary.totalHourBooking}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {user.user.purchaseSummary.totalFitnessClassSession}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {user.user.purchaseSummary.totalPackage}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {user.user.purchaseSummary.totalSubscription}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {user.user.purchaseSummary.firstPurchaseAt
              ? user.user.purchaseSummary.firstPurchaseAt
              : "-"}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {user.user.purchaseSummary.lastPurchaseAt
              ? user.user.purchaseSummary.lastPurchaseAt
              : "-"}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer size="m" />
            {(getUserRole().some((role) => role === "merchant-owner") ||
              getUserPermission().some(
                (permission) =>
                  permission === "ms-operation-read" || permission === "ms-operation-write",
              )) && (
              <>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Text>Hourly Bookings - </Text>
                  <Spacer size="xs" position="left" />
                  <Text
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.colors.text.link,
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `/user/hourly_booking_details`,
                        search: `userId=${user.user.id}`,
                        state: user,
                      })
                    }
                  >
                    show all
                  </Text>
                </Box>
                <Spacer />

                <Table size="small">
                  <TableHeader
                    headerCells={[
                      "Order Type",
                      "Gym Name",
                      "Booking Date",
                      "Time",
                      "Promo Code",
                      "Status",
                    ]}
                    nonSortableColumns={[
                      "Order Type",
                      "Gym Name",
                      "Booking Date",
                      "Time",
                      "Promo Code",
                      "Status",
                    ]}
                  />
                  <TableBody>
                    {user.hourlyBookings.length > 0 ? (
                      user.hourlyBookings.map((booking) => (
                        <TableRow key={booking.id}>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textTransform: "capitalize", textAlign: "center" }}
                            >
                              {booking.type.replaceAll("_", " ")}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {booking.location.name}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {booking.date}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {booking.time}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {booking.promoCode === null || booking.promoCode === ""
                                ? "-"
                                : booking.promoCode}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textTransform: "capitalize", textAlign: "center" }}
                            >
                              {booking.status}
                            </Text>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow colSpan={10}>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <Spacer size="m" />
              </>
            )}

            <Spacer size="m" />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Text>Classes - </Text>
              <Spacer size="xs" position="left" />
              <Text
                sx={{
                  fontSize: "14px",
                  color: theme.palette.colors.text.link,
                  cursor: "pointer",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() =>
                  history.push({
                    pathname: `/user/fitness_class_details`,
                    search: `userId=${user.user.id}`,
                    state: user,
                  })
                }
              >
                show all
              </Text>
            </Box>
            <Spacer />

            <Table size="small">
              <TableHeader
                headerCells={[
                  "Order Type",
                  "Gym Name",
                  "Booking Date",
                  "Time",
                  "Category",
                  "Title",
                  "Trainer",
                  "Promo Code",
                  "Status",
                ]}
                nonSortableColumns={[
                  "Order Type",
                  "Gym Name",
                  "Booking Date",
                  "Time",
                  "Category",
                  "Title",
                  "Trainer",
                  "Promo Code",
                  "Status",
                ]}
              />
              <TableBody>
                {user.classes.length > 0 ? (
                  user.classes.map((userClass) => (
                    <TableRow key={userClass.id}>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {userClass.type.replaceAll("_", " ")}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textAlign: "center" }}
                        >
                          {userClass.location.name}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textAlign: "center" }}
                        >
                          {userClass.date}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textAlign: "center" }}
                        >
                          {userClass.time}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textAlign: "center" }}
                        >
                          {userClass.session?.class.category.label
                            ? userClass.session?.class.category.label
                            : "-"}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textAlign: "center" }}
                        >
                          {userClass.session?.class.name ? userClass.session?.class.name : "-"}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <List
                          sx={{ listStyleType: "disc", padding: 0, pl: 3, textAlign: "center" }}
                        >
                          {userClass.type.includes("merchant_class_session") &&
                          userClass.session?.trainer ? (
                            <ListItem
                              key={userClass.session?.trainer.id}
                              sx={{
                                display: "list-item",
                                padding: 0,
                                paddingBottom: "5px",
                                color: theme.palette.colors.text.table,
                              }}
                            >
                              {`${userClass.session?.trainer.firstName} ${userClass.session?.trainer.lastName}`}
                            </ListItem>
                          ) : (
                            <Text variant="body2" type="TableText" noWrap={true}>
                              -
                            </Text>
                          )}
                        </List>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textAlign: "center" }}
                        >
                          {userClass.promoCode ? userClass.promoCode : "-"}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          variant="body2"
                          type="TableText"
                          noWrap={true}
                          sx={{ textTransform: "capitalize", textAlign: "center" }}
                        >
                          {userClass.status}
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Spacer size="m" />
            {(getUserRole().some((role) => role === "merchant-owner") ||
              getUserPermission().some(
                (permission) =>
                  permission === "ms-operation-read" || permission === "ms-operation-write",
              )) && (
              <>
                <Spacer size="m" />
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Text>Subscriptions - </Text>
                  <Spacer size="xs" position="left" />
                  <Text
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.colors.text.link,
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `/user/subscription_details`,
                        search: `userId=${user.user.id}`,
                        state: user,
                      })
                    }
                  >
                    show all
                  </Text>
                </Box>
                <Spacer />

                <Table size="small">
                  <TableHeader
                    headerCells={[
                      "Title",
                      "Description",
                      "Start At",
                      "End At",
                      "No. of Days",
                      "Status",
                    ]}
                    nonSortableColumns={[
                      "Title",
                      "Description",
                      "Start At",
                      "End At",
                      "No. of Days",
                      "Status",
                    ]}
                  />
                  <TableBody>
                    {user.subscriptions.length > 0 ? (
                      user.subscriptions.map((subscription) => (
                        <TableRow key={subscription.id}>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {subscription.plan.title}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {subscription.plan.shortDescription}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {subscription.start_at ? subscription.start_at : "-"}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {subscription.end_at ? subscription.end_at : "-"}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {subscription.plan.numberOfDays}
                            </Text>
                          </TableCell>

                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textTransform: "capitalize", textAlign: "center" }}
                            >
                              {subscription.status.replaceAll("_", " ")}
                            </Text>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow colSpan={10}>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <Spacer size="m" />

                <Spacer size="m" />
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Text>Packages - </Text>
                  <Spacer size="xs" position="left" />
                  <Text
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.colors.text.link,
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `/user/package_details`,
                        search: `userId=${user.user.id}`,
                        state: user,
                      })
                    }
                  >
                    show all
                  </Text>
                </Box>
                <Spacer />

                <Table size="small">
                  <TableHeader
                    headerCells={["Title", "Created At", "Code", "Status"]}
                    nonSortableColumns={["Title", "Created At", "Code", "Status"]}
                  />
                  <TableBody>
                    {user.packages.length > 0 ? (
                      user.packages.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {item.plan.title}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {item.createdAt ? item.createdAt : "-"}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {item.code}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textTransform: "capitalize", textAlign: "center" }}
                            >
                              {item.status.replaceAll("_", " ")}
                            </Text>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow colSpan={10}>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <Spacer size="m" />
                <Spacer size="m" />
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Text>Events - </Text>
                  <Spacer size="xs" position="left" />
                  <Text
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.colors.text.link,
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `/user/event_details`,
                        search: `userId=${user.user.id}`,
                        state: user,
                      })
                    }
                  >
                    show all
                  </Text>
                </Box>
                <Spacer />

                <Table size="small">
                  <TableHeader
                    headerCells={["Business Name", "Date", "Time", "Title", "Promo Code", "Status"]}
                    nonSortableColumns={[
                      "Business Name",
                      "Date",
                      "Time",
                      "Title",
                      "Promo Code",
                      "Status",
                    ]}
                  />
                  <TableBody>
                    {user.events.length > 0 ? (
                      user.events.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {item.location.name}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {item.date}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {item.time}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {item.event.title}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textAlign: "center" }}
                            >
                              {item.promoCode ? item.promoCode : "-"}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              variant="body2"
                              type="TableText"
                              noWrap={true}
                              sx={{ textTransform: "capitalize", textAlign: "center" }}
                            >
                              {item.status}
                            </Text>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow colSpan={10}>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <Spacer size="m" />
              </>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

UserTableRow.propTypes = {
  user: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      registeredAt: PropTypes.string,
      phoneNumber: PropTypes.string,
      gender: PropTypes.string,
      dateOfBirth: PropTypes.string,
      imagePath: PropTypes.string,
      purchaseSummary: PropTypes.shape({
        totalHourBooking: PropTypes.number,
        totalFitnessClassSession: PropTypes.number,
        totalPackage: PropTypes.number,
        totalSubscription: PropTypes.number,
        firstPurchaseAt: PropTypes.string,
        lastPurchaseAt: PropTypes.string,
      }),
    }),
    hourlyBookings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        date: PropTypes.string,
        status: PropTypes.string,
        time: PropTypes.string,
        promoCode: PropTypes.string,
        location: PropTypes.shape({
          name: PropTypes.string,
          address: PropTypes.string,
        }),
        session: PropTypes.shape({
          trainer: PropTypes.shape({
            id: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            imagePath: PropTypes.string,
          }),
          class: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.shape({
              label: PropTypes.string,
            }),
          }),
        }),
      }),
    ),
    classes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        date: PropTypes.string,
        status: PropTypes.string,
        time: PropTypes.string,
        promoCode: PropTypes.string,
        location: PropTypes.shape({
          name: PropTypes.string,
          address: PropTypes.string,
          lat: PropTypes.string,
          long: PropTypes.string,
          imagePath: PropTypes.string,
        }),
        session: PropTypes.shape({
          trainer: PropTypes.shape({
            id: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            imagePath: PropTypes.string,
          }),
          class: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.shape({
              label: PropTypes.string,
            }),
          }),
        }),
      }),
    ),
    subscriptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        start_at: PropTypes.string,
        end_at: PropTypes.string,
        plan: PropTypes.shape({
          title: PropTypes.string,
          shortDescription: PropTypes.string,
          numberOfDays: PropTypes.number,
        }),
      }),
    ),
    packages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        code: PropTypes.string,
        createdAt: PropTypes.string,
        plan: PropTypes.shape({
          title: PropTypes.string,
        }),
      }),
    ),
    events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        time: PropTypes.string,
        date: PropTypes.string,
        event: PropTypes.shape({
          title: PropTypes.string,
        }),
        location: PropTypes.shape({
          name: PropTypes.string,
        }),
        promoCode: PropTypes.string,
      }),
    ),
  }).isRequired,
};
